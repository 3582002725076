<template>
  <el-dialog title="车辆出险查询结果" width="900" :visible.sync="visible" :fullscreen="full" :close-on-click-modal="false" :close-on-press-escape="false" custom-class="v-dialog v-dialog--highLight risk-dialog" @open="computeHeight">
    <div>
      <tool :full-screen.sync="full" @download="download('车辆出险查询结果')"></tool>
      <h2 class="risk-dialogTitle">车辆出险查询结果</h2>
      <table class="risk-dialogTable">
        <colgroup>
          <col width="150"/>
          <col width="250"/>
        </colgroup>
        <thead>
          <tr>
            <th>标题</th>
            <th>内容</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in v.result.result.singleResult.insurance" :key="index">
            <td>{{item.title}}</td>
            <td>{{item.content}}</td>
          </tr>

        </tbody>
      </table>

      <table class="risk-dialogTable">
        <colgroup>
          <col width="150" />
          <col width="250" />
        </colgroup>
        <thead>
          <tr>
            <th>理赔信息</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in v.result.result.singleResult.claim" :key="index">
            <td>{{item}}</td>
          </tr>
          <tr>
            <td v-if="!v.result.result.singleResult.claim.length">没有数据</td>
          </tr>
        </tbody>
      </table>
    </div>

  </el-dialog>
</template>

<script>
import mixin from './mixin'
export default {
  data() {
    return {
      visible: false,
      v: null
    }
  },
  mixins: [mixin],
  methods: {
    open(datas) {
      this.visible = true
      this.v = Object.assign(this.v, datas)
    }
  },
  created() {
    // 初始化数据
    this.v = {}
  }
}
</script>
